import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {FlagProvider} from '@unleash/proxy-client-react';
import {ConfigProvider} from 'src/client.webstore/Context/ConfigProvider';
import {StyleProvider} from 'src/client.webstore/Context/StyleProvider';
import baseClient from 'src/client.webstore/api/base';
import _ from 'lodash';
import storeConfig from 'src/client.webstore/utils/storeConfig';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';

// Lazy load components
const WebstoreRouter = React.lazy(() => import('src/client.webstore/Views/webstoreRouter'));
const CartIcon = React.lazy(() => import('src/client.webstore/Views/Elements/CartIcon'));
const FeatureGroups = React.lazy(() => import('src/client.webstore/Views/FeatureGroups'));
const CartSideSheet = React.lazy(() => import('src/client.webstore/Views/Cart/Components/CartSideSheet'));

console.info('PSS Webstores', {
    e: process.env.NODE_ENV,
    v: VERSION
});

/**
 * The class representing WebstoreComponents located on the DOM at `PSS.WebstoreComponents` or `window.PSS.WebstoreComponents`.
 * Members of this class represent fully composed & mountable components for the webstore.
 *
 *
 * @example
 * let webstore = new window.PSS.WebstoreComponents();
 * webstore.mountStore('div#app');
 *
 * @class
 */
export class WebstoreComponents {
    static config = {
        endpoint: process.env.NODE_ENV === 'production' ? 'https://webstores.powersportsupport.com' : 'http://webstores.dev.powersportsupport.com',
        provider: 'ARI',
        styleOverrides: {},
        cart: {},
        hashRouter: false
    };

    // Feature toggles
    static togglesConfig = {
        url: process.env.TOGGLES_URL,
        clientKey: process.env.TOGGLES_KEY,
        refreshInterval: process.env.TOGGLES_REFRESH_INTERVAL,
        appName: 'webstore',
        environment: VERSION !== 'develop' && VERSION !== 'staging' ? 'production' : 'development',
        context: {
            properties: {}
        }
    };

    static ready; //this will probably need to be refactored

    /**
     * @param {?WebstoreComponents.config} config Configuration object.
     */
    constructor(config = null) {
        WebstoreComponents.config = Object.assign({}, WebstoreComponents.config, config);
        let client = baseClient(WebstoreComponents.config.endpoint);

        WebstoreComponents.ready = new Promise((resolve, reject) => {
            client.get('/webstore/webstore_cart/config')
                .then(res => {
                    WebstoreComponents.config.hashRouter = _.get(res.data, 'route', '') === 'hash';
                    WebstoreComponents.config.cart = Object.assign({}, res.data, WebstoreComponents.config.cart);

                    // Set style overrides from the configured cart config, which can be overridden by the current style overrides
                    WebstoreComponents.config.styleOverrides = Object.assign(
                        {},
                        _.pickBy(WebstoreComponents.config.cart.styles || {}, (value, key) => {
                            // Only style overrides with non-empty values may be set
                            return !_.isEmpty(value);
                        }),
                        WebstoreComponents.config.styleOverrides
                    );

                    // Set dashboard_domain to togglesConfig context to allow for it to filter toggles in the hostname strategy
                    WebstoreComponents.togglesConfig.context.properties.hostname = _.get(res.data, 'dashboard_domain');

                    // Save into local storage
                    _.map(WebstoreComponents.config, (v, k) => storeConfig.setItem(k, v));

                    // set the ecs cluster type into baseClient property to be used by other requests
                    client.set(res.data.pss_ct);
                })
                .then(resolve);
        });
    }

    /**
     * Mount the *entire* webstore application in `selector`.
     * @function
     * @param {string} selector The DOM target this component should be mounted in to.
     */
    mountStore = (selector = 'div#webstore') => {
        Bugsnag.start({
            apiKey: 'ef80d97993654f6c74cab46cb178b855',
            appVersion: VERSION,
            releaseStage: process.env.NODE_ENV,
            plugins: [new BugsnagPluginReact()]
        });

        const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

        WebstoreComponents.ready.then(() => {
            ReactDOM.render(
                <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                        <FlagProvider config={WebstoreComponents.togglesConfig}>
                            <ConfigProvider cart={WebstoreComponents.config.cart}>
                                <StyleProvider styleOverrides={WebstoreComponents.config.styleOverrides}>
                                    <WebstoreRouter hashRouter={WebstoreComponents.config.hashRouter} />
                                </StyleProvider>
                            </ConfigProvider>
                        </FlagProvider>
                    </Suspense>
                </ErrorBoundary>,
                document.querySelector(selector)
            );
        });
    };

    /**
     * Mount a group of featured products
     * @function
     * @param {string} featureGroupName The name of the feature group the user has created in the PSS Dashboard
     * @param {string|Element}selector The destination `Element` this component should be rendered within
     *
     * @example
     * webstore.mountFeatureGroup('AFX Helmets', 'div#some-container');
     *
     */
    mountFeatureGroup = (featureGroupName, selector) => {
        let mountPoint = selector;
        if (typeof selector === 'string') {
            mountPoint = document.querySelector(selector);
        }

        WebstoreComponents.ready.then(() => {
            ReactDOM.render(
                <Suspense fallback={<Spinner />}>
                    <ConfigProvider cart={WebstoreComponents.config.cart}>
                        <StyleProvider styleOverrides={WebstoreComponents.config.styleOverrides}>
                            <FeatureGroups name={featureGroupName} hashRouter={WebstoreComponents.config.hashRouter} />
                        </StyleProvider>
                    </ConfigProvider>
                </Suspense>,
                mountPoint
            );
        });
    };

    /**
     * Mount a small floating shopping cart icon.
     * @deprecated
     * @function
     */
    mountCartIcon = () => {
        WebstoreComponents.ready.then(() => {
            let element = document.createElement('div');
            element.id = 'pss-cart-node';

            document.body.appendChild(element);

            ReactDOM.render(
                <Suspense fallback={<Spinner />}>
                    <ConfigProvider cart={WebstoreComponents.config.cart}>
                        <StyleProvider styleOverrides={WebstoreComponents.config.styleOverrides}>
                            <CartIcon hashRouter={WebstoreComponents.config.hashRouter} />
                        </StyleProvider>
                    </ConfigProvider>
                </Suspense>,
                element
            );
        });
    };

    /**
     * Mounts and opens the shopping cart sidesheet external of the PSS render tree
     * @function
     */
    mountCartSideSheet = () => {
        WebstoreComponents.ready.then(() => {
            let sideSheetTarget = document.querySelector('div#pss-cart-sidesheet');
            if (!sideSheetTarget) {
                sideSheetTarget = document.createElement('div');
                sideSheetTarget.id = 'pss-cart-sidesheet';
                document.body.appendChild(sideSheetTarget);
            }

            ReactDOM.render(
                <Suspense fallback={<Spinner />}>
                    <ConfigProvider cart={WebstoreComponents.config.cart}>
                        <StyleProvider styleOverrides={WebstoreComponents.config.styleOverrides}>
                            <CartSideSheet
                                onClose={() => ReactDOM.unmountComponentAtNode(sideSheetTarget)}
                                hashRouter={WebstoreComponents.config.hashRouter}
                            />
                        </StyleProvider>
                    </ConfigProvider>
                </Suspense>,
                sideSheetTarget
            );
        });
    };

}
