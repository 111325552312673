import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';

const Wrapper = styled.div`
.disabled, button:disabled, select:disabled, input:disabled {
  cursor: not-allowed;
}

form {
  width: 100%;
}
`;

export const ThemeContext = React.createContext();

export class StyleProvider extends React.Component {
    state = {
        loading: true,
        cssUrl: null,
        jsonUrl: null,
        modulesUrl: null,
        styles: null,
        vars: {}
    };

    static propTypes = {
        styleOverrides: PropTypes.object
    };

    static defaultProps = {
        styleOverrides: {}
    };

    static sassServer = 'https://styles.pss.cloud';

    // Dynamic Sass Server based on domain
    static fetchBootstrap = (cssUrl) => axios
        .get(
            StyleProvider.sassServer,
            {
                params: {
                    cssUrl: cssUrl
                }
            }
        );

    static fetchWithVars = () => axios
        .get(
            StyleProvider.sassServer,
            {
                params: {
                    primary: getComputedStyle(document.documentElement).getPropertyValue('--primary'),
                    secondary: getComputedStyle(document.documentElement).getPropertyValue('--secondary')
                }
            }
        );

    static fetchWithOverrides = overrides => axios
        .get(StyleProvider.sassServer, {params: overrides});


    componentDidMount() {
        let ariSheets = _.chain(document.styleSheets).filter(v => /published-assets.ari-build.com/.test(v.href)).value();

        let dsSheets = _.chain(document.styleSheets).filter(v => /\/styles\/styles\.css/.test(v.href)).value();

        let promise = new Promise(() => true);
        switch (true) {
            case !_.isEmpty(this.props.styleOverrides):
                promise = StyleProvider
                    .fetchWithOverrides(this.props.styleOverrides);
                break;
            case ariSheets.length > 0:
                promise = StyleProvider
                    .fetchBootstrap(ariSheets[0].href);
                break;
            case dsSheets.length > 0:
                promise = StyleProvider
                    .fetchBootstrap(dsSheets[0].href);
                break;
            default:
                promise = new Promise((resolve, reject) => {
                    setTimeout(() => resolve(StyleProvider.fetchWithVars()), 200);
                });
        }

        promise
            .then(res => {
                this.setState({...res.data});
                return res.data.jsonUrl;
            })
            .then(jsonUrl => axios.get(jsonUrl))
            .then(res => this.setState({styles: res.data, loading: false}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let link = document.querySelector('link#pss-modules');
        if (this.state.modulesUrl !== null && link === null) {
            link = document.createElement('link');
            link.href = this.state.modulesUrl;
            link.rel = 'stylesheet';
            link.id = 'pss-modules';
            document.head.appendChild(link);
        }
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        return (
            <ThemeContext.Provider
                value={{
                    styles: this.state.styles,
                    cs: classNames.bind(this.state.styles),
                    vars: this.state.vars
                }}
            >
                <Wrapper>
                    {this.props.children}
                </Wrapper>
            </ThemeContext.Provider>
        );
    }
}
